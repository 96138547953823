<template>
    <aside class="app-back-header">
        <router-link class="app-back-header__link" to="/">{{ back }}</router-link>
    </aside>
</template>

<script lang="ts">
import { computed, defineComponent } from '@vue/runtime-core';
import { translate } from '@/features/i18n/i18n-translate';

export default defineComponent({
    setup: () => ({
        back: computed(() => translate('app', 'header', 'back')),
    }),
});
</script>

<style lang="scss">
.app-back-header {
    position: relative;
    z-index: 1;
    display: flex;
    width: 100%;
    margin-top: 1rem;
    /*
    padding-top: 6.5rem;
    &:before {
        content: '';
        z-index: -1;
        @include position-corner;
        bottom: calc(100% - 5.5rem);
        position: absolute;
        background: url('/bg.jpg') top no-repeat;
        background-size: cover;
    }
    */
    &__link {
        width: 100%;
        @include container-small;
        text-decoration: none;
        font-size: 1.1rem;
        align-items: flex-start;
        &:before {
            content: '\f1e6';
            font-family: 'Material Icons';
            margin-right: 0.625rem;
        }
    }
}
</style>
